import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import Layout from '../../../components/Layout';
import SEO from '../../../components/seo';
import Content from '../../../components/Content';
import Block from '../../../components/Block';
import Hero from '../../../components/Hero';
import {getServices} from '../../../selectors/data';
import ServiceItem from '../../../components/ServiceItem';
import SectorGridList from '../../../components/GridList';
import {lang} from '../../../helpers/lang';

const services = getServices();

const renderServices = (count, lang) => {
	return (
		services
			// .slice(0, count)
			.map(sector => (
				<ServiceItem id={sector.key} key={sector.key} lang={lang} />
			))
	);
};

const ParentServicesPage = ({pageContext}) => {
	const data = useStaticQuery(graphql`
		{
			background: file(relativePath: {eq: "pages/services/services.jpg"}) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	`);

	return (
		<Layout>
			<SEO title="Services" />
			<Hero
				backgroundImageFluid={data.background.childImageSharp.gatsbyImageData}
			>
				<h1>Products & services</h1>
				<p></p>
			</Hero>
			<Block dark accent className="gst-block-work">
				<Content>
					<SectorGridList>{renderServices(10, lang)}</SectorGridList>
				</Content>
			</Block>
		</Layout>
	);
};

export default ParentServicesPage;
