import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const GridList = ({children, className}) => (
	<div className={`${classNames('gst-grid-list', className)}`}>{children}</div>
);

GridList.propTypes = {
	children: PropTypes.node.isRequired,
};

export default GridList;
